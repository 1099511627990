import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { content, breakpoints, title, border, flex } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import SeoLiveScore from '../../components/Content/LiveScore/SeoLiveScore';
import TopTen from '../../components/shared/TopTen';
import sports from '../../img/sports.svg';
import CookieDisableComponent from '../../components/shared/CookieDisableError';

const Wrapper = styled('div')`
  height: 100%;
  margin-bottom: 60px;
  p {
    margin-bottom: 1rem;
  }
`;

const FeaturedWrapper = styled('div')`
  position: relative;
  margin: 15px 0 0;
  width: 100%;

  ${title};
  & > h1 {
    left: 95px;
  }

  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius} ${border.borderRadius} 0 0;
    margin-bottom: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      height: 140px;
    }
    .breadcrumbs {
      position: static;
      margin: 10px 0;
    }

    & h1 {
      left: 71px;
    }
  }
`;

const Content = styled('div')`
  > div:last-child {
    padding: 40px;
    margin: 40px 40px 50px 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .liveScoreFrame {
    width: 93%;
    border: none;
    margin-top: 60px;
  }

  @media (max-width: calc(${breakpoints.lg} - 100px)) {
    & .liveScoreFrame {
      width: 92%;
      margin-top: 0;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & .liveScoreFrame {
      width: 97%;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .liveScoreFrame {
      width: 97%;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .liveScoreFrame {
      width: 100%;
      margin: 30px 0;
    }
    > div:last-child {
      padding: 20px;
      margin: 40px 0;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
`;

const ContentWrapper = styled('div')`
  ${content};
  height: 100%;

  @media (max-width: ${breakpoints.sm}) {
    ${flex};
    flex-direction: column;
  }
`;

const Sidebar = styled('div')`
  width: 268px;

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

const PageTemplate = ({ data, pageContext }) => {
  const { wordpressPage } = data;
  const { featured_media, yoast_meta, title, path, acf } = wordpressPage;
  const { localFile, alt_text } = featured_media;
  const {
    top_10_game_1,
    top_10_game_2,
    top_10_game_3,
    top_10_game_4,
    top_10_game_5,
    top_10_game_6,
    top_10_game_7,
    top_10_game_8,
    top_10_game_9,
    top_10_game_10,
  } = acf;
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta;
  const { related, breadcrumbs } = pageContext;
  const metaTitle = (yoast_meta && yoast_wpseo_title) || `${title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc = (yoast_meta && yoast_wpseo_metadesc) || '';
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout>
        <SEO title={metaTitle} description={metaDesc} pathname={path} breadcrumbs={breadcrumbs} />
        <Wrapper>
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={title} />
            {featured_media && (
              <Img fluid={localFile.childImageSharp.fluid} alt={alt_text || title} fadeIn={false} loading="eager" />
            )}
            <h1>
              {title}
              <img className="iconImage" src={sports} alt="Sports" />
            </h1>
          </FeaturedWrapper>
          {related.length && (
            <ContentWrapper>
              <Content>
                <SeoLiveScore />
                <iframe
                  className="liveScoreFrame"
                  title="Inline Frame Example"
                  width="90%"
                  height="1000px"
                  src="https://www.buaksib.com/embed-livescores/"
                />
              </Content>
              <Sidebar>
                <TopTen
                  top_10_1={top_10_game_1}
                  top_10_2={top_10_game_2}
                  top_10_3={top_10_game_3}
                  top_10_4={top_10_game_4}
                  top_10_5={top_10_game_5}
                  top_10_6={top_10_game_6}
                  top_10_7={top_10_game_7}
                  top_10_8={top_10_game_8}
                  top_10_9={top_10_game_9}
                  top_10_10={top_10_game_10}
                />
              </Sidebar>
            </ContentWrapper>
          )}
        </Wrapper>
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        top_10_game_1 {
          description
          title
          url
        }
        top_10_game_2 {
          description
          title
          url
        }
        top_10_game_3 {
          description
          title
          url
        }
        top_10_game_4 {
          description
          title
          url
        }
        top_10_game_5 {
          description
          title
          url
        }
        top_10_game_10 {
          description
          title
          url
        }
        top_10_game_6 {
          description
          title
          url
        }
        top_10_game_7 {
          description
          title
          url
        }
        top_10_game_8 {
          description
          title
          url
        }
        top_10_game_9 {
          description
          title
          url
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
