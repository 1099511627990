import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { color, border, breakpoints } from '../../../utils/style';

const Bg = styled('div')`
  border-radius: ${border.borderRadius};
  @media (max-width: ${breakpoints.md}) {
    margin: 0;
    padding: 10px 15px 0 0;
  }

  @media (max-width: ${breakpoints.xs}) {
    padding-right: 0;
  }

  .compress {
    margin-top: 60px;

    p {
      overflow: hidden;
      padding: 0 30px 0 0;

      @media (max-width: ${breakpoints.sm}) {
        padding: 0;
      }

      @media (max-height: ${breakpoints.xs}) {
        padding: 0;
      }
    }

    a {
      img {
        width: calc(50% - 40px);
      }
      @media (max-width: ${breakpoints.md}) and (max-height: ${breakpoints.md}) {
        img {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    
    @media (max-width: ${breakpoints.md}) {
      margin-top: 20px;
    }
  }

  .button {
    a {
      padding: 10px 20px;
    }
    span {
      margin-right: 10px;
      font-size: 16px;
      line-height: 1.4;
      color: ${color.lightGrey};
    }
    svg {
      margin-top: -6px;
    }
  }
`;

// REFACTOR
// Useless div
const SeoLiveScore = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPage(filter: { slug: { eq: "live-score" } }) {
          edges {
            node {
              acf {
                seo_content
              }
            }
          }
        }
      }
    `}
    render={({ allWordpressPage }) => (
      <Bg>
        <div
          className="compress"
          dangerouslySetInnerHTML={{ __html: allWordpressPage?.edges[0].node.acf.seo_content || null }}
        />
      </Bg>
    )}
  />
);

export default SeoLiveScore;
